<template>
  <div :class="tableContainerClasses">
    <table :class="['ck-table',modalTableBool?'modal':'', tableClasses]" :id="idTabla">
      <thead :class="tableHeaderClasses">
        <tr :class="tableRowClasses">
          <th v-if="selectable" style="width:115px !important;" class="py-1">
            <v-checkbox :disabled="disabledCheck" class="py-0 my-1" hide-details v-model="selectAll"></v-checkbox>
          </th>
          <th
            scope="col"
            :key="column.name"
            v-for="column in columns"
            :class="headerClasses(column)"
            :style="'width: ' + column.width + '%'"
            @click="column.filterable ? sort(column) : null"
          >
            <div class="align-center" :style="`display: flex; justify-content: ${column.align == null? 'left' : column.align}; margin-top:auto; margin-bottom:auto;`">
              {{ column.label }}
              <template v-if="column.filterable">
                <div
                  class="filter align-center pt-1"
                  v-if="column.filterable && column.name == currentSort && Order == 'asc'"
                >
                  <i class="material-icons">expand_less</i>
                </div>
                <div class="filter align-center pt-1" v-else>
                  <i class="material-icons">expand_more</i>
                </div>
              </template>
            </div>
          </th>
        </tr>
      </thead>
      <slot> </slot>
    </table>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
export default {
  data() {
    return {
      currentSort: "",
      selectAll: false,
    };
  },
  props: {
    idTabla: {
      type: String,
      default: "tableScroll",
    },
    modalTableBool: {
      type: [Boolean],
      default: false,
    },
    dir: {
      type: String,
      default: "",
    },
    Order: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: () => [],
      required: true,
    },
    sortKey: {
      type: String,
      default: "",
    },
    sortOrders: {
      type: Object,
      default: () => ({}),
    },
    tableClasses: {
      type: Object,
      default: () => ({}),
    },
    tableHeaderClasses: {
      type: Object,
      default: () => ({
        "p-3": true,
        "text-left": true,
      }),
    },
    tableRowClasses: {
      type: Object,
      default: () => ({}),
    },
    tableContainerClasses: {
      type: Object,
      default: () => ({}),
    },

    selectable: {
      type: [Boolean],
      default: false,
    },
    disabledCheck: {
        type: Boolean,
        default: false
    }
  },
   watch: {
    selectAll: {
      handler: function(newValue) {
        this.$emit("selectAll", this.selectAll);
      },
    },
  },
  methods: {
    headerClasses(column) {
      let classes = this.tableHeaderClasses;
      classes["table-header-sorting"] = column.filterable;
      return classes;
    },
    sort(column) {
      this.currentSort = column.name;
      this.$emit("sort", column.name, column.columnName);
    },
    tableScroll(){
        const elementScroll = document.querySelector(`#${this.idTabla} tbody`);
        if(elementScroll) {
            const ps = new PerfectScrollbar(elementScroll, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20
            });
            /* var ps = new PerfectScrollbar(elementScroll);

            var scrollbarY = elementScroll.querySelector('.ps__rail-y');

            elementScroll.onscroll = function () {
                scrollbarY.style.cssText = `
                                                top: ${this.scrollTop}px!important; 
                                                background-color:#f6f5f6 !important; 
                                                right: ${-this.scrollLeft}px
                                            `;
            } */
        }
    },
  },
  mounted() {
      this.tableScroll();
  },
  updated(){
      this.tableScroll();
  }
};
</script>

<style scoped>
/*
    .filter-asc {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;
        margin-bottom: 1px;
    }
    .filter-desc {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
        margin-top: 1px;
    } 
    .active-filter-asc {
        border-bottom: 5px solid #ccc;
    }
    .active-filter-desc {
        border-top: 5px solid #ccc;
    }
    */
    .filter {
        color: #96999a;
        cursor: pointer;
    }
    .inline-block {
        display: inline-block;
    }
    /*
    .table-header-sorting {
        cursor: pointer;
    }*/
    table {
        border-collapse: collapse;
        width: 100%;
        /* white-space: nowrap; */
        overflow-x: scroll;
        /* overflow-y: scroll; */
    }

    th{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: #828282;
        background-color: #f6f5f6;
        padding: 13px 45px 13px 45px;
    }
    th > div{
        max-width: 15rem;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }
    td{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        color: #1E2245;
        background-color: #fdfcfc;
        padding: 13px 45px 13px 45px;
        letter-spacing: -0.34px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
    }

    td > div{
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 15rem;
        margin-right: auto;
        margin-left: auto;
    }
    td > .loading{
        max-width: 100% !important;
    }
  
/*
    tr:nth-child(1n){

        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #EFF1FB;
    
    }
    
    tr:hover{background-color: #FCFCFC}
    
    th {
    
        background-color: #f3f4f6;
        color:#96999A;
        font-weight: 500;
    }
*/
    /**Cambios nuevos tabla kardex */
    table.ck-table thead {
        padding: 1000px !important;
    
    }
    table.ck-table thead tr th:first-child {
    border-top-left-radius: 20px !important;
    margin:100px!important;
    }
    table.ck-table thead tr th:last-child {
    border-top-right-radius: 20px;
    }

    table.ck-table.modal thead tr th:first-child {
    border-top-left-radius: 0px !important;
    margin:100px!important;
    }
    table.ck-table.modal thead tr th:last-child {
    border-top-right-radius: 0px;
    }

    table.ck-table thead.no-radius tr th:first-child {
      border-top-left-radius: 0px !important;
    }
    table.ck-table thead.no-radius tr th:last-child {
      border-top-right-radius: 0px;
    }
    table.no-box-shadow{
      box-shadow: none
    }
    /**Fin tabla kardex */
    tbody {
      display:block;
      max-height:60vh;
      min-width: 1000px;
      background-color: #fdfcfc;
    }
    tr {
      display:table;
      table-layout:fixed;
      width: 100%;
    }
    table.ck-table.modal  tbody {
      display:block;
      max-height:60vh;
      min-width: 700px;
      background-color: #fdfcfc;
    }


</style>
